import classnames from 'classnames';

import Checkbox from 'bloko/blocks/checkbox';
import ControlGroup from 'bloko/blocks/controlGroup';
import { FormItem } from 'bloko/blocks/form';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { NovaFilterKey } from 'lux/models/novaFilters';
import FilterCount from 'src/components/NovaFilters/components/FilterCount';
import useChangeVerifiedFilters from 'src/components/NovaFilters/resumes/useChangeVerifiedFilters';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import sortForLanguages from 'src/utils/sortForLanguages';

import LanguageLevelSelect from 'src/components/ResumeLanguageFilter/LanguageLevelSelect';
import LanguageSelect from 'src/components/ResumeLanguageFilter/LanguageSelect';
import RemoveButton from 'src/components/ResumeLanguageFilter/RemoveButton';

interface ResumeLanguageFilterFormItemWithVerificationProps {
    languageSection: string;
    value: string[];
    selectedLanguages: string[];
    preTitleDataQa: string;
    isVertical?: boolean;
    updateValue: (oldLanguage: string, newLanguage: string) => void;
    removeValue: (removingLanguage: string) => void;
}

const TrlKeys = {
    addVerified: 'clusters.verifiedLanguages',
};
const ResumeLanguageFilterFormItemWithVerification: TranslatedComponent<
    ResumeLanguageFilterFormItemWithVerificationProps
> = ({
    trls,
    languageSection,
    removeValue,
    selectedLanguages,
    updateValue,
    value,
    preTitleDataQa,
    isVertical = true,
}) => {
    const collection = useSelector((state) => sortForLanguages(state.languages.language)) || [];
    const [code, grade] = languageSection.split('.');
    const searchCluster = useSelector(({ searchClusters }) => searchClusters);
    const levels = useSelector((state) => state.languageLevels.level);
    const { searchId, handleChangeVerifiedLanguage, resetVerifiedLanguage } = useChangeVerifiedFilters(languageSection);
    const verifiedLanguages = searchCluster[NovaFilterKey.VerifiedLanguages];
    const selectedValues = verifiedLanguages.selectedValues;

    return (
        <FormItem data-qa={`${preTitleDataQa}-blok`}>
            <div className={classnames({ 'resume-search-item__language': !isVertical })}>
                <ControlGroup vertical={isVertical}>
                    <LanguageSelect
                        code={code}
                        onChange={({ target }) => updateValue(languageSection, `${target.value}.${grade}`)}
                        selectedLanguages={selectedLanguages}
                        collection={collection}
                        isVertical={isVertical}
                        dataQa={`${preTitleDataQa}-filter-language`}
                    />
                    <LanguageLevelSelect
                        grade={grade}
                        onChange={({ target }) => {
                            resetVerifiedLanguage();
                            updateValue(languageSection, `${code}.${target.value}`);
                        }}
                        levels={levels}
                        isVertical={isVertical}
                        dataQa={`${preTitleDataQa}-filter-language-level`}
                    />
                    {searchId !== null && (
                        <>
                            <VSpacing base={2} />
                            <Checkbox
                                onChange={() => {
                                    handleChangeVerifiedLanguage();
                                    updateValue(languageSection, value[0]);
                                }}
                                checked={selectedValues.includes(searchId)}
                            >
                                <span>{trls[TrlKeys.addVerified]}</span>
                                <FilterCount count={verifiedLanguages.groups[searchId].count} />
                            </Checkbox>
                        </>
                    )}
                </ControlGroup>
                {!isVertical && (
                    <RemoveButton
                        onClick={() => removeValue(languageSection)}
                        dataQa={`${preTitleDataQa}__language-delete`}
                    />
                )}
            </div>
        </FormItem>
    );
};

export default translation(ResumeLanguageFilterFormItemWithVerification);
