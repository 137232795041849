import { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import { VSpacingContainer, VSpacing as MagritteVSpacing } from '@hh.ru/magritte-ui';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MetroType, NovaFilterKey, NovaFilterMetroGroup, NovaFilterMetroGroupMap } from 'lux/models/novaFilters';
import { EMPTY_CLUSTER } from 'lux/models/searchClusters';
import MagritteNovaChipsList from 'src/components/NovaFilters/components/Magritte/NovaChipsList';
import MagritteNovaChipsSelect, {
    ChipsSelectType as MagritteChipsSelectType,
} from 'src/components/NovaFilters/components/Magritte/NovaChipsSelect';
import MagritteNovaFiltersCardItem from 'src/components/NovaFilters/components/Magritte/NovaFiltersCardItem';
import MagritteNovaMobileFilterWithActivator from 'src/components/NovaFilters/components/Magritte/NovaMobileFilterWithActivator';
import NovaChipsList from 'src/components/NovaFilters/components/NovaChipsList';
import NovaChipsSelect, { ChipsSelectType } from 'src/components/NovaFilters/components/NovaChipsSelect';
import NovaModal from 'src/components/NovaFilters/components/NovaModal';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import { isMetroCheckedOrIndeterminate } from 'src/components/NovaFilters/novaFilterUtils';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import MetroCheckbox from 'src/components/NovaFilters/vacancies/Metro/MetroCheckbox';
import { renderLineIcon } from 'src/components/NovaFilters/vacancies/Metro/MetroLine';
import { renderPointIcon } from 'src/components/NovaFilters/vacancies/Metro/MetroPoint';
import useSearchMetro from 'src/components/NovaFilters/vacancies/Metro/hooks/useSearchMetro';
import { sortMetroByTitleDesc } from 'src/components/NovaFilters/vacancies/Metro/utils/sort';

import styles from './metro-mobile.less';

const sortMetroGroupsByTitleDesc = (groups: NovaFilterMetroGroupMap) =>
    Object.values(groups).sort(sortMetroByTitleDesc);

const TrlKeys = {
    add: 'vacancySearch.metro.add',
    title: 'searchvacancy.clusters.metro',
    inputPlaceholder: 'search.clusters.input.placeholder',
    [MetroType.Line]: 'vacancySearch.metro.lines',
    [MetroType.Station]: 'vacancySearch.metro.stations',
};

const MetroMobile: TranslatedComponent<{ title: string; isMagritte?: boolean }> = ({ trls, title, isMagritte }) => {
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();

    // global state data
    const { groups, selectedValues } =
        useSelector((state) => state.searchClusters?.[NovaFilterKey.Metro]) || EMPTY_CLUSTER;

    // chips, search list
    const [metroList, setMetroList] = useState<NovaFilterMetroGroup[]>([]);
    const [metroListByType, setMetroListByType] = useState<NovaFilterMetroGroup[]>([]);
    const [selectedMetroType, setSelectedMetroType] = useState<MetroType>(MetroType.Station);

    // modal data
    const [showModal, setShowModal] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');

    // need update chips list after spa/ajax requests
    useEffect(() => {
        const list: NovaFilterMetroGroup[] = sortMetroGroupsByTitleDesc(groups);
        setMetroList(list);
        setMetroListByType(list.filter(({ type }) => type === MetroType.Station));
    }, [groups]);

    const optionsChips = useMemo(() => {
        return metroList
            .filter(({ id }) => selectedValues.includes(id))
            .map(({ id, title, type, color }) => ({
                id,
                title: (
                    <span className={styles.metroMobile}>
                        {type === MetroType.Station && (
                            <span className={styles.metroMobileStationIcon}>{renderPointIcon(color)}</span>
                        )}
                        {type === MetroType.Line && (
                            <span className={styles.metroMobileLineIcon}>{renderLineIcon(color)}</span>
                        )}
                        <span className={classnames({ [styles.metroMobileLineTitle]: type === MetroType.Line })}>
                            {title}
                        </span>
                    </span>
                ),
            }));
    }, [metroList, selectedValues]);

    // TODO: для addon должен использоваться компонент из магритта https://jira.hh.ru/browse/PORTFOLIO-31375
    const optionsMagritteChips = useMemo(() => {
        return metroList
            .filter(({ id }) => selectedValues.includes(id))
            .map(({ id, title, type, color }) => ({
                id,
                title,
                addon: (
                    <>
                        {type === MetroType.Station && renderPointIcon(color, isMagritte)}
                        {type === MetroType.Line && renderLineIcon(color, isMagritte)}
                    </>
                ),
            }));
    }, [isMagritte, metroList, selectedValues]);

    const handleClear = useCallback(
        (metro: string) => {
            const values = selectedValues.filter((item) => item !== metro);
            filterUpdate(values, NovaFilterKey.Metro);
            sendCountsRequest();
        },
        [filterUpdate, selectedValues, sendCountsRequest]
    );

    const { isSearchMetroEnabled, searchMetroList, searchMetroDebounced } = useSearchMetro();

    const handleMetro = useCallback(
        (metro: string, group: NovaFilterMetroGroup) => {
            let values = [...selectedValues];
            const index = values.indexOf(metro);
            if (index !== -1) {
                values.splice(index, 1);
            } else {
                // if use line filter
                // delete all station for selected line
                if (group.type === MetroType.Line) {
                    values = values.filter((item) => item.split('.')[0] !== group.lineId);
                }
                // if use station filter - delete line
                if (group.type === MetroType.Station) {
                    const [line] = metro.split('.');
                    values = values.filter((item) => item !== line);
                }
                values.push(metro);
            }
            filterUpdate(values, NovaFilterKey.Metro);

            const haveSelectedGroup = metroList.filter(({ id }) => id === metro).length > 0;
            if (!haveSelectedGroup) {
                const searchMetro = searchMetroList.find(({ id }) => id === metro);
                if (searchMetro) {
                    const newList = [searchMetro, ...metroList].sort(sortMetroByTitleDesc);
                    setMetroList(newList);
                    setMetroListByType(newList.filter(({ type }) => type === MetroType.Station));
                }
            }
        },
        [filterUpdate, metroList, searchMetroList, selectedValues]
    );

    const filterMetro = useCallback(
        (metroType: MetroType) => setMetroListByType(metroList.filter(({ type }) => type === metroType)),
        [metroList]
    );

    const onChangeSearchQuery = useCallback(
        (value: string) => {
            setSearchQuery(value);
            searchMetroDebounced(value);
        },
        [searchMetroDebounced]
    );

    const onCloseOrBack = () => {
        setShowModal(false);
        onChangeSearchQuery('');
        setSelectedMetroType(MetroType.Station);
        filterMetro(MetroType.Station);
        sendCountsRequest();
    };

    if (isMagritte) {
        // для метро должен использоваться selection bottom sheet https://jira.hh.ru/browse/PORTFOLIO-31376
        return (
            <MagritteNovaMobileFilterWithActivator
                title={trls[TrlKeys.title]}
                titleModal={trls[TrlKeys.title]}
                add={trls[TrlKeys.add]}
                showModal={showModal}
                setShowModal={setShowModal}
                selected={
                    <MagritteNovaChipsList
                        name={NovaFilterKey.Metro}
                        options={optionsMagritteChips}
                        onClear={(id) => {
                            handleClear(id);
                            sendCountsRequest();
                        }}
                    />
                }
                onChangeSearchQuery={onChangeSearchQuery}
                searchQuery={searchQuery}
                onBack={onCloseOrBack}
                onClose={onCloseOrBack}
                additionalOptions={
                    <>
                        {!isSearchMetroEnabled && (
                            <>
                                <MagritteVSpacing default={12} />
                                <MagritteNovaChipsSelect
                                    name="metro_type"
                                    selected={[selectedMetroType]}
                                    data-qa="serp-settings__metro-type"
                                    options={Object.values(MetroType).map((type) => ({
                                        id: type,
                                        title: trls[TrlKeys[type]],
                                    }))}
                                    selectType={MagritteChipsSelectType.Radio}
                                    onChange={(values: string[]) => {
                                        const metroType = values[0] as MetroType;
                                        setSelectedMetroType(metroType);
                                        filterMetro(metroType);
                                    }}
                                />
                            </>
                        )}
                    </>
                }
                content={
                    <VSpacingContainer default={12}>
                        {(isSearchMetroEnabled ? searchMetroList : metroListByType).map((item) => {
                            const { id, color, type, ...metro } = item;
                            const { checked, indeterminate } = isMetroCheckedOrIndeterminate(
                                id,
                                selectedValues,
                                color,
                                type
                            );
                            return (
                                <MagritteNovaFiltersCardItem
                                    item={{ id, title: metro.title, count: 0 }}
                                    key={id}
                                    name={NovaFilterKey.Metro}
                                    checked={checked}
                                    indeterminate={indeterminate}
                                    onChange={(id) => handleMetro(id, item)}
                                    left={
                                        <span>
                                            {type === MetroType.Station && renderPointIcon(color, isMagritte)}
                                            {type === MetroType.Line && renderLineIcon(color, isMagritte)}
                                        </span>
                                    }
                                />
                            );
                        })}
                    </VSpacingContainer>
                }
                activatorDataQa="novafilters-mobile-add-metro"
            />
        );
    }

    return (
        <>
            <Text Element="span" size={TextSize.Large} strong>
                {trls[TrlKeys.title]}
            </Text>
            <VSpacing base={3} />
            <NovaChipsList
                name={NovaFilterKey.Metro}
                options={optionsChips}
                onClear={(id) => {
                    handleClear(id);
                    sendCountsRequest();
                }}
            />
            <VSpacing base={4} />
            <Link
                appearance={LinkAppearance.Pseudo}
                data-qa="novafilters-mobile-add-metro"
                onClick={() => {
                    setShowModal(!showModal);
                }}
            >
                {trls[TrlKeys.add]}
            </Link>
            <VSpacing base={6} />
            <NovaModal
                onChangeSearchQuery={onChangeSearchQuery}
                searchQuery={searchQuery}
                title={title}
                searchPlaceholder={trls[TrlKeys.inputPlaceholder]}
                showModal={showModal}
                onBack={onCloseOrBack}
                onClose={onCloseOrBack}
            >
                {showModal && (
                    <>
                        {!isSearchMetroEnabled && (
                            <>
                                <VSpacing base={1} />
                                <NovaChipsSelect
                                    name={'metro_type'}
                                    selected={[selectedMetroType]}
                                    data-qa="serp-settings__metro-type"
                                    options={Object.values(MetroType).map((type) => ({
                                        id: type,
                                        title: trls[TrlKeys[type]],
                                    }))}
                                    selectType={ChipsSelectType.Single}
                                    onChange={(values: string[]) => {
                                        const metroType = values[0] as MetroType;
                                        setSelectedMetroType(metroType);
                                        filterMetro(metroType);
                                    }}
                                />
                                <VSpacing base={3} />
                            </>
                        )}
                        {(isSearchMetroEnabled ? searchMetroList : metroListByType).map(
                            ({ id, color, type, ...metro }) => {
                                const { checked, indeterminate } = isMetroCheckedOrIndeterminate(
                                    id,
                                    selectedValues,
                                    color,
                                    type
                                );
                                return (
                                    <MetroCheckbox
                                        key={id}
                                        id={id}
                                        name={NovaFilterKey.Metro}
                                        color={color}
                                        type={type}
                                        checked={checked}
                                        indeterminate={indeterminate}
                                        onChange={handleMetro}
                                        xsView
                                        hideCount
                                        {...metro}
                                    />
                                );
                            }
                        )}
                    </>
                )}
            </NovaModal>
        </>
    );
};

export default translation(MetroMobile);
