import { useMemo } from 'react';

import { FormLabel, VSpacing, NumberInput } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { CurrencyType } from 'lux/models/currencies.types';
import Currency from 'src/components/Currency';
import NovaChipsSelect, { ChipsSelectType } from 'src/components/NovaFilters/components/Magritte/NovaChipsSelect';
import useNovaFilterUpdateByCurrency from 'src/components/NovaFilters/hooks/useNovaFilterUpdateByCurrency';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

interface CompensationMobileProps {
    amount: string;
    onChange: (value: string) => void;
    title: string;
}

const TrlKeys = {
    placeholder: 'employer.resumesSearch.salary.from',
};

const CompensationMobile: TranslatedComponent<CompensationMobileProps> = ({ trls, onChange, amount, title }) => {
    const sendCountsRequest = useDebouncedCountsRequest();
    const setCurrency = useNovaFilterUpdateByCurrency();
    const currency = useSelector((state) => state.criteriaCurrencyCode);
    const currencies = useSelector((state) => state.currencies.list);

    const options = useMemo(
        () =>
            currencies.map(({ code }) => ({
                id: code,
                title: (
                    <span className={styles.currency}>
                        <Currency value={code} fullLabel />
                    </span>
                ),
            })),
        [currencies]
    );

    return (
        <>
            <FormLabel>{title}</FormLabel>
            <VSpacing default={12} />
            <NumberInput
                value={amount}
                data-qa="novafilters-mobile-custom-compensation"
                placeholder={trls[TrlKeys.placeholder]}
                onChange={onChange}
                allowNegative={false}
                decimalLength={0}
            />
            <VSpacing default={12} />
            <NovaChipsSelect
                name="search_currency"
                selected={[currency]}
                data-qa="serp-settings__search-currency"
                options={options}
                selectType={ChipsSelectType.Radio}
                onChange={(values) => {
                    setCurrency(values[0] as CurrencyType);
                    sendCountsRequest();
                }}
            />
            <VSpacing default={24} />
        </>
    );
};

export default translation(CompensationMobile);
