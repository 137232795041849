import { useRef } from 'react';

import { FormLabel, VSpacing as MagritteVSpacing, Input, UncontrolledInput } from '@hh.ru/magritte-ui';
import InputText from 'bloko/blocks/inputText';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { KeyCode } from 'bloko/common/constants/keyboard';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { NovaFilterKey } from 'lux/models/novaFilters';
import ListItem from 'src/components/NovaFilters/components/ListItem';
import MagritteListItem from 'src/components/NovaFilters/components/Magritte/ListItem';
import MagritteNovaControl from 'src/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'clusters.excluded_text',
    placeholder: 'novaFilters.excludedText',
};

const ExcludedText: TranslatedComponent<{ isMagritte?: boolean }> = ({ isMagritte, trls }) => {
    const inputWrapperRef = useRef<HTMLDivElement>(null);
    const needSendRequest = useRef<boolean>(true);
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();
    const selectedValue = useSelector(
        (state) => state.searchClusters?.[NovaFilterKey.ExcludedText].selectedValues[0] || ''
    );
    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.keyCode === KeyCode.Enter) {
            needSendRequest.current = false;
            filterUpdate([event.currentTarget.value], NovaFilterKey.ExcludedText);
        }
    };

    if (isMagritte) {
        return (
            <MagritteNovaControl
                mobileView={
                    <>
                        <FormLabel>{trls[TrlKeys.title]}</FormLabel>
                        <MagritteVSpacing default={12} />
                        <Input
                            clearable
                            value={selectedValue}
                            placeholder={trls[TrlKeys.placeholder]}
                            data-qa="novafilters-mobile-excluded-text-input"
                            onChange={(value) => {
                                filterUpdate([value], NovaFilterKey.ExcludedText, true);
                                sendCountsRequest();
                            }}
                        />
                        <MagritteVSpacing default={24} />
                    </>
                }
            >
                <MagritteNovaFilterWrapper title={trls[TrlKeys.title]}>
                    <MagritteListItem>
                        <UncontrolledInput
                            value={selectedValue}
                            data-qa="novafilters-excluded-text-input"
                            placeholder={trls[TrlKeys.placeholder]}
                            onBlur={({ target }) => {
                                if (needSendRequest.current) {
                                    filterUpdate([target.value], NovaFilterKey.ExcludedText);
                                } else {
                                    needSendRequest.current = true;
                                }
                            }}
                            onKeyDown={onKeyDown}
                        />
                    </MagritteListItem>
                </MagritteNovaFilterWrapper>
            </MagritteNovaControl>
        );
    }

    return (
        <NovaControl
            mobileView={
                <>
                    <Text Element="span" size={TextSize.Large} strong>
                        {trls[TrlKeys.title]}
                    </Text>
                    <VSpacing base={3} />
                    <InputText
                        showClearButton
                        value={selectedValue}
                        placeholder={trls[TrlKeys.placeholder]}
                        data-qa="novafilters-mobile-excluded-text-input"
                        onChange={(value) => {
                            filterUpdate([value], NovaFilterKey.ExcludedText, true);
                            sendCountsRequest();
                        }}
                    />
                    <VSpacing base={6} />
                </>
            }
        >
            <NovaFilterWrapper title={trls[TrlKeys.title]}>
                <ListItem>
                    <div ref={inputWrapperRef}>
                        <InputText
                            defaultValue={selectedValue}
                            data-qa="novafilters-excluded-text-input"
                            placeholder={trls[TrlKeys.placeholder]}
                            onBlur={({ target }) => {
                                if (needSendRequest.current) {
                                    filterUpdate([target.value], NovaFilterKey.ExcludedText);
                                } else {
                                    needSendRequest.current = true;
                                }
                            }}
                            onKeyDown={onKeyDown}
                        />
                    </div>
                </ListItem>
            </NovaFilterWrapper>
        </NovaControl>
    );
};

export default translation(ExcludedText);
