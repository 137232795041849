import { useState } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { NovaFilterKey } from 'lux/models/novaFilters';
import FilterList from 'src/components/NovaFilters/components/FilterList';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import NovaFiltersItem from 'src/components/NovaFilters/components/NovaFiltersItem';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { NOVAFILTERS_CRITERIA_ETC_FILTERS } from 'src/components/NovaFilters/novaFilterUtils';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'searchvacancy.clusters.etc',
};

const EtcFilter: TranslatedComponent = ({ trls }) => {
    const filterUpdate = useNovaFilterUpdate();
    const { groups, selectedValues } = useSelector((state) => state.searchClusters[NovaFilterKey.Etc]);
    const [etcValues, setEtcValues] = useState(selectedValues || {});
    return (
        <NovaFilterWrapper title={trls[TrlKeys.title]}>
            <FilterList>
                {NOVAFILTERS_CRITERIA_ETC_FILTERS.map((name) => {
                    const group = groups[name];
                    const values = etcValues[name];
                    if (!values || !group) {
                        return null;
                    }
                    const updatedGroup = { ...group, id: values[0] };
                    return (
                        <NovaFiltersItem
                            key={updatedGroup.id}
                            item={updatedGroup}
                            name={name}
                            onChange={() => {
                                setEtcValues({});
                                filterUpdate({}, NovaFilterKey.Etc);
                            }}
                            checked={values.includes(updatedGroup.id)}
                        />
                    );
                })}
            </FilterList>
        </NovaFilterWrapper>
    );
};

export default translation(EtcFilter);
