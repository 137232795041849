import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useBreakpoint } from '@hh.ru/magritte-ui';
import urlParser from 'bloko/common/urlParser';

import Debug from 'HHC/Debug';
import { CriteriaKey } from 'lux/models/search/searchCriteria.types';
import { EmployerCriteria } from 'lux/models/vacancySearchCriteria';
import { useNotification } from 'src/components/Notifications/Provider';
import { useUpdateAbortController } from 'src/components/NovaFilters/NovaFilterUpdateContext';
import { useSearchParams } from 'src/components/NovaFilters/actions/sendFilterForm/getSearchParams/useSearchParams';
import { useSelector } from 'src/hooks/useSelector';

import fetchEmployerVacancies from 'lux/models/employerVacancySearch/fetchEmployerVacancies';

type UseGetEmployerVacancies = () => {
    getVacanciesByPageNumber: (page: number, showLoad?: boolean) => void;
    getVacancyListDefault: () => void;
    getVacanciesByCriteria: (params: Partial<EmployerCriteria>) => void;
};

const useGetEmployerVacancies: UseGetEmployerVacancies = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const searchParams = useSearchParams();
    const abortAndGetUpdatedSignal = useUpdateAbortController();
    const { isMobile } = useBreakpoint();
    const employerId = useSelector(({ employerInfo }) => employerInfo.id);

    const getVacanciesByCriteria = useCallback(
        (params: Partial<EmployerCriteria>) => {
            const query = urlParser.stringify({ ...searchParams, ...params } as never);
            const abortSignal = abortAndGetUpdatedSignal();
            dispatch(
                fetchEmployerVacancies({
                    query,
                    abortSignal,
                    isMobile,
                    addNotification,
                })
            ).catch(console.error);
        },
        [abortAndGetUpdatedSignal, addNotification, dispatch, isMobile, searchParams]
    );
    const getVacanciesByPageNumber = useCallback(
        (page: number) => {
            getVacanciesByCriteria({
                [CriteriaKey.Page]: page,
            });
        },
        [getVacanciesByCriteria]
    );

    const getVacancyListDefault = useCallback(() => {
        if (!employerId) {
            Debug.log('out error', new Error('employerId is missing'));
            return;
        }
        const query = urlParser.stringify({ [CriteriaKey.CurrentEmployerId]: employerId, getVacancyCount: 'true' });
        const abortSignal = abortAndGetUpdatedSignal();
        dispatch(
            fetchEmployerVacancies({
                query,
                abortSignal,
                isMobile,
                addNotification,
            })
        ).catch(console.error);
    }, [abortAndGetUpdatedSignal, addNotification, dispatch, employerId, isMobile]);

    const value = useMemo(
        () => ({ getVacanciesByPageNumber, getVacancyListDefault, getVacanciesByCriteria }),
        [getVacanciesByCriteria, getVacanciesByPageNumber, getVacancyListDefault]
    );

    return value;
};

export default useGetEmployerVacancies;
