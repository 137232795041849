import { Badge, HSpacingContainer, Title, Link as MagritteLink, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { NovaFilterKey } from 'lux/models/novaFilters';
import paths from 'src/app/routePaths';
import { NovaFilterSetting } from 'src/components/NovaFilters/novaFilterUtils';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

const TrlKeys = {
    filterName: 'novafilters.similarSavedSearch.filter',
    clear: 'novafilters.similarSavedSearch.clear',
    explanation: 'novafilters.similarSavedSearch.explanation',
    confirm: 'novafilters.similarSavedSearch.confirm',
};

const TotalFiltersCount: TranslatedComponent = ({ trls }) => {
    const { totalUsedFilters, hash } = useSelector((state) => {
        const resume = state.searchClusters?.[NovaFilterKey.Resume];
        return {
            totalUsedFilters: state.vacancySearchResult.totalUsedFilters,
            hash: resume && resume.selectedValues.length > 0 ? resume.selectedValues[0] : '',
        };
    });

    return (
        <>
            <div className={styles.filtersCountContainer}>
                <HSpacingContainer default={12}>
                    <Title Element="h3" size="small">
                        {trls[TrlKeys.filterName]}
                    </Title>
                    {!!totalUsedFilters && (
                        <div className={styles.filtersCount}>
                            <Badge>{`${totalUsedFilters}`}</Badge>
                        </div>
                    )}
                </HSpacingContainer>
                <MagritteLink
                    to={`${paths.vacancySearch}?resume=${hash}&${NovaFilterSetting.ForceFiltersSaving}=true`}
                    Element={Link}
                >
                    {trls[TrlKeys.clear]}
                </MagritteLink>
            </div>
            <VSpacing default={12} />
        </>
    );
};

export default translation(TotalFiltersCount);
