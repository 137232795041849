import { useMemo } from 'react';

import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { NovaFilterKey } from 'lux/models/novaFilters';
import NovaFilterWithAdditionalList from 'src/components/NovaFilters/components/NovaFilterWithAdditionalList';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'clusters.district',
    placeholder: 'novafilters.district.search',
    add: 'vacancySearch.district.add',
};

const WILDCARD = '%QUERY%';
const REMOTE = `/autosuggest/multiprefix/v2?q=${WILDCARD}&d=districts_{area}`;

const District: TranslatedComponent<{ isMagritte?: boolean }> = ({ isMagritte, trls }) => {
    const selectedArea = useSelector((state) => state.searchClusters?.[NovaFilterKey.Area]?.selectedValues) || [];
    const groups = useSelector((state) => state.searchClusters?.[NovaFilterKey.District]?.groups) || {};

    const isZP = useIsZarplataPlatform();

    const currentArea = selectedArea.length === 1 ? selectedArea[0].toString() : '';

    const dataProvider = useMemo(
        () => createRemoteDataProvider(REMOTE.replace('{area}', currentArea), WILDCARD),
        [currentArea]
    );

    if (isZP || Object.values(groups).length === 0) {
        return null;
    }

    return (
        <NovaFilterWithAdditionalList
            filterName={NovaFilterKey.District}
            dataProvider={dataProvider}
            title={trls[TrlKeys.title]}
            placeholder={trls[TrlKeys.placeholder]}
            placeholderMobile={trls[TrlKeys.title]}
            add={trls[TrlKeys.add]}
            isMagritte={isMagritte}
        />
    );
};

export default translation(District);
