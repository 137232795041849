import { ReactNode, useMemo } from 'react';
import classnames from 'classnames';

import HoverTip, { TipLayer, TipProvider, TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import { FormItem } from 'bloko/blocks/form';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import numberFormatter from 'bloko/common/numberFormatter';
import { formatFromTo } from 'bloko/common/trl';

import { NovaFilterGroup, NovaFilterGroupMap } from 'lux/models/novaFilters';
import translation from 'src/components/translation';
import useIsClient from 'src/hooks/useIsClient';
import { NARROW_NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import { NovaFilterRangeTrls } from 'src/components/NovaFilters/components/Bloko/NovaFilterRange/InputsGroup';

import styles from './novaFiltersChart.less';

const CHART_HEIGHT = 40;
const MIN_CHART_HEIGHT = 4;
const MIN_CHART_ITEMS_COUNT = 5;
const getHeight = (n: number, min: number, max: number) => Math.round(((n - min) / (max - min)) * CHART_HEIGHT);
const getMinMax = (groups: NovaFilterGroupMap<string>) => {
    const values = Object.values(groups)
        .map((item) => item.count)
        .sort((a, b) => a - b);
    const min = Math.log2(values[0]);
    const max = Math.log2(values[values.length - 1]);
    return { min, max };
};

type GetTitleFunction = (v: NovaFilterGroup<string>) => ReactNode;

interface NovaFiltersChartItem {
    height: number;
    title: ReactNode;
    count: number;
    from?: string | number | null;
    to?: string | number | null;
    id: string;
    showHover: boolean;
}

interface NovaFiltersChartProps {
    groups: NovaFilterGroupMap<string>;
    onClick: (data: NovaFiltersChartItem) => void;
    trlsTitles: NovaFilterRangeTrls;
    unitTitle: ReactNode;
    emptyMessage: string;
}

const TrlKeys = {
    empty: 'novaFiltersChart.empty',
};

export const getChartGroups = (
    groups: NovaFilterGroupMap<string>,
    getTitle: GetTitleFunction
): NovaFiltersChartItem[] => {
    const { min, max } = getMinMax(groups);
    return Object.entries(groups).map(([key, { count, from, to, id }]) => {
        const height = getHeight(Math.log2(count), min, max);
        return {
            height: Math.max(height, MIN_CHART_HEIGHT),
            title: getTitle(groups[key]),
            count,
            from,
            to,
            id,
            showHover: height !== 0,
        };
    });
};

const NovaFiltersChart: TranslatedComponent<NovaFiltersChartProps> = ({
    trls,
    groups,
    onClick,
    trlsTitles,
    unitTitle,
    emptyMessage,
}) => {
    const isClient = useIsClient();

    const data: NovaFiltersChartItem[] = useMemo(() => {
        const getTitle: GetTitleFunction = ({ from, to, count }) => {
            const title = formatFromTo(
                { from, to },
                { trlFrom: trlsTitles?.from, trlTo: trlsTitles?.to, trlFromTo: trlsTitles?.fromTo }
            );
            if (title !== null) {
                const formattedCount = numberFormatter.format(count.toString(), {
                    groupSeparator: NARROW_NON_BREAKING_SPACE,
                });
                return (
                    <>
                        {`${title} `}
                        {unitTitle}
                        {` — ${formattedCount} ${trlsTitles?.resumes}`}
                    </>
                );
            }
            return null;
        };
        return getChartGroups(groups, getTitle);
    }, [groups, trlsTitles, unitTitle]);

    if (data.length < MIN_CHART_ITEMS_COUNT) {
        return (
            <div className={styles.novaFilterChartEmpty}>
                {trls[TrlKeys.empty]}
                {emptyMessage}
            </div>
        );
    }
    return (
        <FormItem>
            <div className={styles.novaFilterChart}>
                {data.map((item, index) => {
                    const chartBar = (
                        <div
                            key={index}
                            className={classnames(styles.novaFilterChartBar, {
                                [styles.novaFilterChartBarDisabled]: !item.showHover,
                            })}
                            style={{ height: `${item.height}px` }}
                            onClick={() => item.showHover && onClick(item)}
                        />
                    );
                    if (item.showHover) {
                        return (
                            <TipProvider key={index}>
                                <HoverTip
                                    host={isClient ? document.body : null}
                                    placement={TipPlacement.Top}
                                    layer={TipLayer.Overlay}
                                    render={() => item.title}
                                >
                                    {chartBar}
                                </HoverTip>
                            </TipProvider>
                        );
                    }
                    return chartBar;
                })}
            </div>
        </FormItem>
    );
};

export default translation(NovaFiltersChart);
