import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { NovaFilterKey } from 'lux/models/novaFilters';
import NovaFilterWithChart from 'src/components/NovaFilters/components/NovaFilterWithChart';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'resumesearch.clusters.age',
    unit: 'resumesearch.clusters.age.unit',
    from: 'resumesearch.clusters.age.from',
    to: 'resumesearch.clusters.age.to',
    fromTo: 'resumesearch.clusters.age.fromto',
    labelFrom: 'employer.resumesSearch.ageFrom',
    labelTo: 'employer.resumesSearch.ageTo',
    onlyWithAge: 'employer.resumesSearch.onlyWithAge',
    resumes: 'employer.resumesSearch.resumes',
    emptyMessage: 'novaFilters.age.empty',
};

interface Props {
    name: typeof NovaFilterKey.Age;
}

const Age: TranslatedComponent<Props> = ({ trls }) => {
    const groups = useSelector((state) => state.searchClusters[NovaFilterKey.Age].groups);

    return (
        <div className="novafilter-resume-age">
            <NovaFilterWithChart
                groups={groups}
                title={`${trls[TrlKeys.title]}, ${trls[TrlKeys.unit]}`}
                name={NovaFilterKey.Age}
                emptyMessage={trls[TrlKeys.emptyMessage]}
                trls={{
                    from: trls[TrlKeys.from],
                    to: trls[TrlKeys.to],
                    fromTo: trls[TrlKeys.fromTo],
                    labelFrom: trls[TrlKeys.labelFrom],
                    labelTo: trls[TrlKeys.labelTo],
                    onlyWithTitle: trls[TrlKeys.onlyWithAge],
                    resumes: trls[TrlKeys.resumes],
                }}
            />
        </div>
    );
};

export default translation(Age);
