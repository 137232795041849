import { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { NovaFilterKey } from 'lux/models/novaFilters';
import { searchClustersUpdate } from 'lux/models/searchClusters';
import { getLanguageLevel } from 'src/components/NovaFilters/resumes/useConvertLanguageLevel';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

import AddLinkMagritte from 'src/components/ResumeLanguageFilter/Magritte/AddLink';
import FormItemWithVerificationMagritte from 'src/components/ResumeLanguageFilter/Magritte/FormItemWithVerification';
import RemoveLinkMagritte from 'src/components/ResumeLanguageFilter/Magritte/RemoveLink';
import RemoveLinkBloko from 'src/components/ResumeLanguageFilter/RemoveLink';
import FormItemWithVerificationBloko from 'src/components/ResumeLanguageFilter/ResumeLanguageFilterFormItemWithVerification';
import AddLinkBloko from 'src/components/ResumeLanguageFilter/ResumeLanguageFilterSelected';

interface ResumeLanguageFilterWithVerificationProps {
    value: string[];
    onChange: (value: string[]) => void;
    preTitleDataQa: string;
    isVertical?: boolean;
}

const ResumeLanguageFilterWithVerification: FC<ResumeLanguageFilterWithVerificationProps> = ({
    value,
    onChange,
    preTitleDataQa,
    isVertical = true,
}) => {
    const isMagritte = useMagritte();

    const dispatch = useDispatch();
    const languages = useSelector(({ languages }) => languages);
    const selectedLanguages = useSelector(({ searchClusters }) => searchClusters)[NovaFilterKey.VerifiedLanguages]
        .selectedValues;
    const selectedValues = value.map((language) => language.split('.')[0]);
    const updateValue = (oldLanguage: string, newLanguage: string) =>
        onChange(value.map((language) => (language === oldLanguage ? newLanguage : language)));

    const removeValue = (removingLanguage: string) =>
        onChange(value.filter((language) => language !== removingLanguage));

    const FormItemWithVerification = isMagritte ? FormItemWithVerificationMagritte : FormItemWithVerificationBloko;
    const RemoveLink = isMagritte ? RemoveLinkMagritte : RemoveLinkBloko;
    const AddLink = isMagritte ? AddLinkMagritte : AddLinkBloko;

    return (
        <>
            {value.map((languageSection) => {
                const searchId = getLanguageLevel({ languages: languages.language, record: languageSection });
                return (
                    <Fragment key={languageSection}>
                        <FormItemWithVerification
                            languageSection={languageSection}
                            value={value}
                            selectedLanguages={selectedValues}
                            preTitleDataQa={preTitleDataQa}
                            isVertical={isVertical}
                            updateValue={updateValue}
                            removeValue={removeValue}
                        />
                        {isVertical && (
                            <RemoveLink
                                onClick={() => {
                                    dispatch(
                                        searchClustersUpdate({
                                            data: selectedLanguages.filter((language) => language !== searchId),
                                            filter: NovaFilterKey.VerifiedLanguages,
                                        })
                                    );
                                    removeValue(languageSection);
                                }}
                                dataQa={`${preTitleDataQa}-delete-language`}
                            />
                        )}
                        <input type="hidden" name="language" value={languageSection} />
                    </Fragment>
                );
            })}
            <AddLink value={value} onChange={onChange} preTitleDataQa={preTitleDataQa} isVertical={isVertical} />
        </>
    );
};

export default ResumeLanguageFilterWithVerification;
