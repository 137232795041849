import { FC, useState } from 'react';

import { MenuItem } from 'bloko/blocks/drop';
import ClickMenu, { MenuPlacement, MenuLayer } from 'bloko/blocks/drop/Menu/Click';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import BlokoTranslateGuard from 'bloko/blocks/translateGuard';

import { NovaFilterKey } from 'lux/models/novaFilters';
import Currency from 'src/components/Currency';
import useNovaFilterUpdateByCurrency from 'src/components/NovaFilters/hooks/useNovaFilterUpdateByCurrency';
import { useSelector } from 'src/hooks/useSelector';

/**
 * Свойства компонента.
 *
 * @prop [parentFilterKey] Ключ кластера фильтров в котором находится выбор валюты. Необходим для корректного
 * позиционирования подсказки после изменения валюты.
 */
interface Props {
    parentFilterKey?: NovaFilterKey;
    onCurrencySelectorChange?: () => void;
}

/**
 * Компонент переключения валюты в фильтрах поиска.
 */
const CurrencySelector: FC<Props> = ({ parentFilterKey, onCurrencySelectorChange }) => {
    const setCurrency = useNovaFilterUpdateByCurrency();
    const criteriaCurrencyCode = useSelector((state) => state.criteriaCurrencyCode);
    const list = useSelector((state) => state.currencies.list);

    const [selectorHost, setSelectorHost] = useState<HTMLDivElement | null>(null);

    return (
        <div ref={(element) => element && setSelectorHost(element)}>
            <ClickMenu
                placement={MenuPlacement.BottomEnd}
                render={(close) => (
                    <>
                        {list.map(({ code }) => (
                            <MenuItem
                                key={code}
                                onClick={() => {
                                    onCurrencySelectorChange?.();
                                    setCurrency(code, parentFilterKey);
                                    close?.();
                                }}
                            >
                                <Currency value={code} />
                            </MenuItem>
                        ))}
                    </>
                )}
                host={selectorHost}
                layer={MenuLayer.AboveOverlayContent}
            >
                <Link appearance={LinkAppearance.Pseudo}>
                    <BlokoTranslateGuard useSpan>
                        <Currency value={criteriaCurrencyCode} />
                    </BlokoTranslateGuard>
                </Link>
            </ClickMenu>
        </div>
    );
};

export default CurrencySelector;
