import { Radio } from '@hh.ru/magritte-ui';
import { FormItem } from 'bloko/blocks/form';
import BlokoRadio from 'bloko/blocks/radio';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { NovaFilterGroup, NovaFilterGroupMap, NovaFilterKey } from 'lux/models/novaFilters';
import { Gender as GenderType } from 'lux/models/resume/resumeCommon.types';
import FilterCount from 'src/components/NovaFilters/components/FilterCount';
import FilterTitle from 'src/components/NovaFilters/components/FilterTitle';
import ListItem from 'src/components/NovaFilters/components/ListItem';
import NovaFilterItemWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import OnlyWithCheckbox from 'src/components/NovaFilters/components/OnlyWithCheckbox';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { UNKNOWN } from 'src/utils/constants/resumeGenders';

const getCount = (item: NovaFilterGroup<GenderType>, groups: NovaFilterGroupMap<GenderType>) => {
    if (item.id === UNKNOWN) {
        return Object.values(groups).reduce((sum, group) => {
            return sum + group.count;
        }, 0);
    }
    return item.count;
};

const TrlKeys = {
    title: 'clusters.gender',
    onlyWith: 'employer.resumesSearch.onlyWithGender',
};

interface Props {
    name: typeof NovaFilterKey.Gender;
}

const Gender: TranslatedComponent<Props> = ({ trls, name }) => {
    const isMagritte = useMagritte();

    const filterUpdate = useNovaFilterUpdate();
    const { groups, selectedValues } = useSelector((state) => state.searchClusters[NovaFilterKey.Gender]);
    return (
        <NovaFilterWrapper title={trls[TrlKeys.title]}>
            {Object.values(groups).map((item) => {
                const disabled = item.id !== UNKNOWN && item?.disabled;
                if (isMagritte) {
                    return (
                        <NovaFilterItemWrapper
                            key={item.id}
                            left={
                                <Radio
                                    name={name}
                                    value={item.id}
                                    onChange={() =>
                                        filterUpdate({ value: item.id, onlyWith: item.id !== UNKNOWN }, name)
                                    }
                                    checked={selectedValues.value === item.id}
                                    disabled={disabled}
                                    dataQaRadio="serp__novafilter-item-text"
                                />
                            }
                            title={item.title}
                            count={getCount(item, groups)}
                            disabled={disabled}
                        />
                    );
                }
                return (
                    <ListItem key={item.id}>
                        <BlokoRadio
                            name={name}
                            value={item.id}
                            onChange={() => filterUpdate({ value: item.id, onlyWith: item.id !== UNKNOWN }, name)}
                            checked={selectedValues.value === item.id}
                            labelProps={{ 'data-qa': 'serp__novafilter-item-text' }}
                            disabled={disabled}
                        >
                            <FilterTitle title={item.title} />
                            {!disabled && <FilterCount count={getCount(item, groups)} />}
                        </BlokoRadio>
                    </ListItem>
                );
            })}
            {isMagritte ? (
                <OnlyWithCheckbox
                    values={selectedValues}
                    onChange={(newValues) => filterUpdate(newValues, name)}
                    name={name}
                    title={trls[TrlKeys.onlyWith]}
                />
            ) : (
                <>
                    <VSpacing base={2} />
                    <FormItem>
                        <OnlyWithCheckbox
                            values={selectedValues}
                            onChange={(newValues) => filterUpdate(newValues, name)}
                            name={name}
                            title={trls[TrlKeys.onlyWith]}
                        />
                    </FormItem>
                </>
            )}
        </NovaFilterWrapper>
    );
};

export default translation(Gender);
