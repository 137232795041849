import React, { useState } from 'react';

import { Radio } from '@hh.ru/magritte-ui';
import BlokoRadio from 'bloko/blocks/radio';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { NovaFilterKey } from 'lux/models/novaFilters';
import FilterTitle from 'src/components/NovaFilters/components/FilterTitle';
import ListItem from 'src/components/NovaFilters/components/ListItem';
import NovaFilterItemWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'resume.relocation',
};

const Relocation: TranslatedComponent = ({ trls }) => {
    const isMagritte = useMagritte();

    const filterUpdate = useNovaFilterUpdate();
    const { groups, selectedValues } = useSelector((state) => state.searchClusters?.[NovaFilterKey.Relocation]);
    const [values, setValues] = useState(selectedValues);
    return (
        <NovaFilterWrapper title={trls[TrlKeys.title]}>
            {groups &&
                Object.values(groups).map((item) =>
                    isMagritte ? (
                        <NovaFilterItemWrapper
                            key={item.id}
                            left={
                                <Radio
                                    value={item.id}
                                    onChange={() => {
                                        setValues(item.id);
                                        filterUpdate(item.id, NovaFilterKey.Relocation);
                                    }}
                                    checked={values === item.id}
                                    dataQaRadio={`serp__novafilter-${NovaFilterKey.Relocation}-${item.id}`}
                                />
                            }
                            title={item.title}
                        />
                    ) : (
                        <ListItem key={item.id}>
                            <BlokoRadio
                                value={item.id}
                                onChange={() => {
                                    setValues(item.id);
                                    filterUpdate(item.id, NovaFilterKey.Relocation);
                                }}
                                checked={values === item.id}
                                data-qa={`serp__novafilter-${NovaFilterKey.Relocation}-${item.id}`}
                                labelProps={{ 'data-qa': 'serp__novafilter-item-text' }}
                            >
                                <FilterTitle title={item.title} />
                            </BlokoRadio>
                        </ListItem>
                    )
                )}
        </NovaFilterWrapper>
    );
};

export default translation(Relocation);
