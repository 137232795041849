import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { NovaFilterKey } from 'lux/models/novaFilters';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';

import RegionSuggestBloko from 'src/components/NovaFilters/components/Bloko/RegionSuggest';
import RegionSuggestMagritte from 'src/components/NovaFilters/components/Magritte/RegionSuggest';

interface Props {
    title: string;
    name: typeof NovaFilterKey.Citizenship | typeof NovaFilterKey.WorkTicket;
}

const RegionSuggest: TranslatedComponent<Props> = (props) => {
    const isMagritte = useMagritte();
    const Component = isMagritte ? RegionSuggestMagritte : RegionSuggestBloko;

    return <Component {...props} />;
};

export default translation(RegionSuggest);
