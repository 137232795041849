import { useDispatch } from 'react-redux';

import { NovaFilterKey } from 'lux/models/novaFilters';
import { searchClustersUpdate } from 'lux/models/searchClusters';
import { useSelector } from 'src/hooks/useSelector';

import useConvertLanguageLevel from 'src/components/NovaFilters/resumes/useConvertLanguageLevel';

interface UseChangeVerifiedFiltersPropsResponse {
    searchId: string | null;
    resetVerifiedLanguage: () => void;
    handleChangeVerifiedLanguage: () => void;
}

const useChangeVerifiedFilters = (languageSection: string): UseChangeVerifiedFiltersPropsResponse => {
    const dispatch = useDispatch();
    const searchCluster = useSelector(({ searchClusters }) => searchClusters);
    const selectedValues = searchCluster[NovaFilterKey.VerifiedLanguages].selectedValues;
    const verifiedLanguagesGroups = searchCluster[NovaFilterKey.VerifiedLanguages].groups;
    const verifiedGroupsLanguagesList = Object.keys(verifiedLanguagesGroups);
    const languageLevelId = useConvertLanguageLevel(languageSection);
    const isLanguageExist = verifiedGroupsLanguagesList.includes(languageLevelId);
    const searchId = isLanguageExist ? languageLevelId : null;

    const updateVerifiedFilters = (updatedValues: string[]) => {
        dispatch(searchClustersUpdate({ data: updatedValues, filter: NovaFilterKey.VerifiedLanguages }));
    };
    const updatedValues = selectedValues.slice();
    const resetVerifiedLanguage = () => {
        const filteredLanguages = selectedValues.filter((language) => language !== searchId);
        updateVerifiedFilters(filteredLanguages);
    };
    const handleChangeVerifiedLanguage = () => {
        if (isLanguageExist && searchId !== null) {
            if (selectedValues.includes(languageLevelId)) {
                resetVerifiedLanguage();
            } else {
                updatedValues.push(searchId);
                updateVerifiedFilters(updatedValues);
            }
        }
    };

    return { searchId, resetVerifiedLanguage, handleChangeVerifiedLanguage };
};

export default useChangeVerifiedFilters;
