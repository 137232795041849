import { FC } from 'react';

import { NovaFilterGroup } from 'lux/models/novaFilters';
import useMagritte from 'src/hooks/useMagritte';

import NovaFiltersItemBloko from 'src/components/NovaFilters/components/Bloko/NovaFiltersItem';
import NovaFiltersItemMagritte from 'src/components/NovaFilters/components/Magritte/NovaFiltersItem';

interface NovaFiltersItemProps {
    item: NovaFilterGroup<string | number>;
    name: string;
    checked: boolean;
    onChange: (id: string) => void;
    truncated?: boolean;
    hideCount?: boolean;
}

const NovaFiltersItem: FC<NovaFiltersItemProps> = (props) => {
    const isMagritte = useMagritte();
    const Component = isMagritte ? NovaFiltersItemMagritte : NovaFiltersItemBloko;

    return <Component {...props} />;
};

export default NovaFiltersItem;
