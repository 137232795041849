import { ReactElement } from 'react';

import { NovaFilterKey, NovaFilterRangeConvertedToStrings, NovaFilterGender } from 'lux/models/novaFilters';
import useMagritte from 'src/hooks/useMagritte';

import OnlyWithCheckboxBloko from 'src/components/NovaFilters/components/Bloko/OnlyWithCheckbox';
import OnlyWithCheckboxMagritte from 'src/components/NovaFilters/components/Magritte/OnlyWithCheckbox';

type AcceptableValueTypes = NovaFilterRangeConvertedToStrings | NovaFilterGender;

interface OnlyWithCheckboxProps<T> {
    values: T;
    onChange: (data: T, forceUpdate?: boolean) => void;
    name: typeof NovaFilterKey.Salary | typeof NovaFilterKey.Age | typeof NovaFilterKey.Gender;
    title: string;
}

const OnlyWithCheckbox = <T extends AcceptableValueTypes>(props: OnlyWithCheckboxProps<T>): ReactElement => {
    const isMagritte = useMagritte();
    const Component = isMagritte ? OnlyWithCheckboxMagritte : OnlyWithCheckboxBloko;

    return <Component {...props} />;
};

export default OnlyWithCheckbox;
