import { useCallback, useRef, useState, ReactNode, FC } from 'react';

import { FormItem } from 'bloko/blocks/form';

import {
    NovaFilterGroupMap,
    NovaFilterKey,
    Range,
    NovaFilterRange,
    NovaFilterRangeConvertedToStrings,
} from 'lux/models/novaFilters';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import OnlyWithCheckbox from 'src/components/NovaFilters/components/OnlyWithCheckbox';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { rangeKeys } from 'src/components/NovaFilters/novaFilterUtils';
import { useSelector } from 'src/hooks/useSelector';

import InputsGroup, {
    NovaFilterRangeTrls,
} from 'src/components/NovaFilters/components/Bloko/NovaFilterRange/InputsGroup';
import NovaFiltersChart from 'src/components/NovaFilters/components/Bloko/NovaFilterRange/NovaFiltersChart';

const convertSelectedValuesToString = (selectedValues: NovaFilterRange): NovaFilterRangeConvertedToStrings => {
    return {
        [Range.From]: `${selectedValues[Range.From] || ''}`,
        [Range.To]: `${selectedValues[Range.To] || ''}`,
        onlyWith: selectedValues.onlyWith,
    };
};

interface NovaFilterWithChartProps {
    groups: NovaFilterGroupMap<string>;
    title: ReactNode;
    name: typeof NovaFilterKey.Salary | typeof NovaFilterKey.Age;
    titleSideElement?: ReactNode;
    unitTitle?: ReactNode;
    emptyMessage?: string;
    trls: NovaFilterRangeTrls;
}

const NovaFilterWithChart: FC<NovaFilterWithChartProps> = ({
    groups,
    title,
    name,
    titleSideElement,
    trls,
    unitTitle = '',
    emptyMessage = '',
}) => {
    const filterUpdate = useNovaFilterUpdate();
    const initialValues = useSelector((state) => {
        return convertSelectedValuesToString(state.searchClusters[name].selectedValues);
    });
    const lastUpdatedValue = useRef(initialValues);
    const [selectedValues, setSelectedValues] = useState(initialValues);

    const updateFilters = useCallback(
        (data?: NovaFilterRangeConvertedToStrings, withoutFormSending?: boolean) => {
            const newValues = data || selectedValues;
            if (
                rangeKeys.some((key) => lastUpdatedValue.current[key] !== newValues[key]) ||
                newValues.onlyWith !== selectedValues.onlyWith
            ) {
                filterUpdate(newValues, name, withoutFormSending);
            }
            if (!withoutFormSending) {
                lastUpdatedValue.current = newValues;
            }
        },
        [selectedValues, filterUpdate, name]
    );

    const onChange = useCallback(
        (data: NovaFilterRangeConvertedToStrings, forceUpdate: boolean | undefined) => {
            setSelectedValues(data);
            updateFilters(data, !forceUpdate);
        },
        [setSelectedValues, updateFilters]
    );
    const onClick = useCallback(
        (data: NovaFilterRange) => {
            const newData: NovaFilterRangeConvertedToStrings = {
                [Range.From]: `${data[Range.From] ?? ''}`,
                [Range.To]: `${data[Range.To] ?? ''}`,
                onlyWith: selectedValues.onlyWith,
            };
            onChange(newData, true);
        },
        [onChange, selectedValues.onlyWith]
    );

    return (
        <NovaFilterWrapper title={title} titleSideElement={titleSideElement}>
            <NovaFiltersChart
                groups={groups}
                onClick={onClick}
                trlsTitles={trls}
                unitTitle={unitTitle}
                emptyMessage={emptyMessage}
            />
            <InputsGroup
                values={selectedValues}
                onChange={onChange}
                updateFilters={updateFilters}
                name={name}
                trls={trls}
            />
            <FormItem>
                <OnlyWithCheckbox values={selectedValues} onChange={onChange} name={name} title={trls.onlyWithTitle} />
            </FormItem>
        </NovaFilterWrapper>
    );
};

export default NovaFilterWithChart;
