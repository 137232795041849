import { FormLabel, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useIsEmployerVacancySearch from 'lux/models/employerVacancySearch/useIsEmployerVacancySearch';
import { SearchPeriodOption } from 'lux/models/vacancySearchCriteria';
import NovaChipsSelect, { ChipsSelectType } from 'src/components/NovaFilters/components/Magritte/NovaChipsSelect';
import useNovaSortUpdate from 'src/components/NovaFilters/hooks/useNovaSortUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    searchPeriodTitle: 'vacancySearch.searchPeriod',
    [SearchPeriodOption.AllTime]: 'vacancySearch.searchPeriod.all',
    [SearchPeriodOption.Month]: 'vacancySearch.searchPeriod.30',
    [SearchPeriodOption.Week]: 'vacancySearch.searchPeriod.7',
    [SearchPeriodOption.ThreeDays]: 'vacancySearch.searchPeriod.3',
    [SearchPeriodOption.Day]: 'vacancySearch.searchPeriod.1',
};

const SearchPeriod: TranslatedComponent = ({ trls }) => {
    const options = useSelector((state) => state.vacancySearchDictionaries.searchPeriod);
    const value = useSelector((state) => state.novaSorts.searchPeriod);
    const handleChangeSort = useNovaSortUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();
    const isEmployerVacancySearch = useIsEmployerVacancySearch();

    if (!options || isEmployerVacancySearch) {
        return null;
    }

    return (
        <>
            <FormLabel>{trls[TrlKeys.searchPeriodTitle]}</FormLabel>
            <VSpacing default={12} />
            <NovaChipsSelect
                name="search_period"
                selected={[`${value}`]}
                data-qa="serp-settings__search-period-menu"
                options={options.map((option) => ({
                    id: option,
                    title: trls[TrlKeys[option]],
                }))}
                selectType={ChipsSelectType.Radio}
                onChange={(values) => {
                    handleChangeSort('searchPeriod', values[0]);
                    sendCountsRequest();
                }}
            />
            <VSpacing default={24} />
        </>
    );
};

export default translation(SearchPeriod);
