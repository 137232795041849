import { useState, useMemo, useCallback, useEffect, FC, ReactNode } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import { DataProvider, RemoteDataProviderResponse } from 'bloko/blocks/suggest/types';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import debounce from 'bloko/common/debounce';

import { NovaFilterKey, NovaFilterGroup } from 'lux/models/novaFilters';
import MagritteNovaChipsList from 'src/components/NovaFilters/components/Magritte/NovaChipsList';
import MagritteNovaFiltersCardItem from 'src/components/NovaFilters/components/Magritte/NovaFiltersCardItem';
import MagritteNovaMobileFilterWithActivator from 'src/components/NovaFilters/components/Magritte/NovaMobileFilterWithActivator';
import NovaChipsList from 'src/components/NovaFilters/components/NovaChipsList';
import NovaFiltersItem from 'src/components/NovaFilters/components/NovaFiltersItem';
import NovaModal from 'src/components/NovaFilters/components/NovaModal';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import { useSelector } from 'src/hooks/useSelector';

interface SearchItem {
    id: string;
    title: string;
}

export interface MobileListProps {
    filterName: typeof NovaFilterKey.Area | typeof NovaFilterKey.District;
    dataProvider: DataProvider;
    title: ReactNode;
    placeholder: string;
    add: string;
    isMagritte?: boolean;
}

const MobileList: FC<MobileListProps> = ({ filterName, dataProvider, title, placeholder, add, isMagritte }) => {
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();

    // global state data
    const { groups, selectedValues } = useSelector((state) => state.searchClusters?.[filterName]);

    // list
    const [list, setList] = useState<NovaFilterGroup<string>[]>([]);

    // modal data
    const [showModal, setShowModal] = useState<boolean>(false);
    const [searchQuery, setQuery] = useState<string>('');
    const [enableSearch, setEnableSearch] = useState(false);
    const [searchItems, setSearchItems] = useState<SearchItem[]>([]);

    const chipsOptions = useMemo(
        () => list.filter(({ id }) => selectedValues.includes(Number(id))),
        [list, selectedValues]
    );

    // need update list after spa/ajax requests
    useEffect(() => {
        setList(Object.values(groups));
    }, [groups]);

    const onChange = useCallback(
        (selected: string) => {
            // update list states
            const newSelected = Number(selected);
            const index = selectedValues.indexOf(newSelected);
            const newSelectedValues = [...selectedValues];
            if (index === -1) {
                newSelectedValues.push(newSelected);
            } else {
                newSelectedValues.splice(index, 1);
            }
            filterUpdate(newSelectedValues, filterName);

            // for suggest list need add update chips list
            const haveSelectedGroup = list.filter(({ id }) => id === selected).length > 0;
            if (!haveSelectedGroup) {
                const searchItem = searchItems.find(({ id }) => id === selected);
                if (searchItem) {
                    const newItems = [...list];
                    newItems.push({ ...searchItem, count: 0, order: 0 });
                    setList(newItems);
                }
            }
        },
        [selectedValues, filterUpdate, filterName, list, searchItems]
    );

    // suggest
    const searchDebounced = useCallback(
        debounce(async (searchText: string) => {
            let results: RemoteDataProviderResponse = { items: [] };
            try {
                results = await dataProvider(searchText.trim().toUpperCase());
            } catch (error) {
                console.error(error);
            } finally {
                if (results.items) {
                    setSearchItems(results.items.map((item) => ({ title: item.text, id: `${item?.id || ''}` })));
                    setEnableSearch(searchText.length > 0);
                }
            }
        }, 400),
        []
    );

    const onChangeSearchQuery = useCallback(
        (value: string) => {
            setQuery(value);
            searchDebounced(value);
        },
        [searchDebounced]
    );

    const onCloseOrBack = () => {
        setShowModal(false);
        sendCountsRequest();
        setQuery('');
        setEnableSearch(false);
    };

    if (isMagritte) {
        // для подобных фильтров должен использоваться selection bottom sheet https://jira.hh.ru/browse/PORTFOLIO-31376
        return (
            <>
                <MagritteNovaMobileFilterWithActivator
                    title={title}
                    titleModal={title}
                    add={add}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    selected={
                        <MagritteNovaChipsList
                            name={filterName}
                            options={chipsOptions}
                            onClear={(id) => {
                                onChange(id);
                                sendCountsRequest();
                            }}
                        />
                    }
                    onChangeSearchQuery={onChangeSearchQuery}
                    searchQuery={searchQuery}
                    onBack={onCloseOrBack}
                    onClose={onCloseOrBack}
                    content={
                        <VSpacingContainer default={12}>
                            {!enableSearch &&
                                showModal &&
                                list.map((item) => (
                                    <MagritteNovaFiltersCardItem
                                        key={item.id}
                                        name={filterName}
                                        item={item}
                                        onChange={onChange}
                                        checked={selectedValues.includes(Number(item.id))}
                                    />
                                ))}
                            {enableSearch &&
                                searchItems.map(({ id, title }) => (
                                    <MagritteNovaFiltersCardItem
                                        key={id}
                                        name={filterName}
                                        item={{ title, id, count: 0, order: 0 }}
                                        onChange={onChange}
                                        checked={selectedValues.includes(Number(id))}
                                    />
                                ))}
                        </VSpacingContainer>
                    }
                    activatorDataQa={`novafilters-mobile-add-${filterName}s`}
                />
            </>
        );
    }

    return (
        <>
            <Text Element="span" size={TextSize.Large} strong>
                {title}
            </Text>
            <VSpacing base={3} />
            <NovaChipsList
                name={filterName}
                options={chipsOptions}
                onClear={(id) => {
                    onChange(id);
                    sendCountsRequest();
                }}
            />
            <VSpacing base={4} />
            <Link
                appearance={LinkAppearance.Pseudo}
                data-qa={`novafilters-mobile-add-${filterName}s`}
                onClick={() => {
                    setShowModal(!showModal);
                }}
            >
                {add}
            </Link>
            <VSpacing base={6} />
            <NovaModal
                onChangeSearchQuery={onChangeSearchQuery}
                searchQuery={searchQuery}
                title={placeholder}
                searchPlaceholder={placeholder}
                showModal={showModal}
                onBack={onCloseOrBack}
                onClose={onCloseOrBack}
            >
                {!enableSearch &&
                    showModal &&
                    list.map((item) => (
                        <NovaFiltersItem
                            key={item.id}
                            name={filterName}
                            item={item}
                            onChange={onChange}
                            checked={selectedValues.includes(Number(item.id))}
                            hideCount
                        />
                    ))}
                {enableSearch &&
                    searchItems.map(({ id, title }) => (
                        <NovaFiltersItem
                            key={id}
                            name={filterName}
                            item={{ title, id, count: 0, order: 0 }}
                            onChange={onChange}
                            checked={selectedValues.includes(Number(id))}
                        />
                    ))}
            </NovaModal>
        </>
    );
};

export default MobileList;
