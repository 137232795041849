import { FC, Fragment } from 'react';

import Checkbox from 'bloko/blocks/checkbox';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import { NovaFilterGroup, NovaFilterKey } from 'lux/models/novaFilters';
import { LabelOption as LabelOptionResume } from 'lux/models/resumeSearchCriteria';
import { LabelOption as LabelOptionVacancy } from 'lux/models/vacancySearchCriteria';
import FilterTitle from 'src/components/NovaFilters/components/FilterTitle';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import { useSelector } from 'src/hooks/useSelector';

export interface LabelMobileProps {
    title: string;
}

const LabelMobile: FC<LabelMobileProps> = ({ title }) => {
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();
    const { groups, selectedValues } = useSelector((state) => state.searchClusters?.[NovaFilterKey.Label]);
    return (
        <div>
            <legend>
                <Text Element="span" size={TextSize.Large} strong>
                    {title}
                </Text>
            </legend>
            <VSpacing base={3} />
            {Object.values(groups).map((item: NovaFilterGroup<LabelOptionResume | LabelOptionVacancy>, index) => (
                <Fragment key={item.id}>
                    {index > 0 && <div className="horizontal-divider" />}
                    <label>
                        <VSpacing base={2} />
                        <Checkbox
                            name={NovaFilterKey.Label}
                            value={item.id}
                            onChange={() => {
                                const index = selectedValues.indexOf(item.id);
                                const newValues = [...selectedValues];
                                if (index === -1) {
                                    newValues.push(item.id);
                                } else {
                                    newValues.splice(index, 1);
                                }
                                filterUpdate(newValues, NovaFilterKey.Label);
                                sendCountsRequest();
                            }}
                            checked={selectedValues.includes(item.id)}
                            data-qa={`novafilters-mobile-${NovaFilterKey.Label}-${item.id}`}
                            labelProps={{ 'data-qa': 'serp__novafilter-item-text' }}
                            disabled={item.disabled}
                        >
                            <FilterTitle title={item.title} />
                        </Checkbox>
                        <VSpacing base={2} />
                    </label>
                </Fragment>
            ))}
            <VSpacing base={6} />
        </div>
    );
};

export default LabelMobile;
