import { FC, useState, useRef } from 'react';

import { Cell, CellText, Link, Drop, useBreakpoint, BottomSheet } from '@hh.ru/magritte-ui';
import { ChevronDownOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import { NovaFilterKey } from 'lux/models/novaFilters';
import Currency from 'src/components/Currency';
import useNovaFilterUpdateByCurrency from 'src/components/NovaFilters/hooks/useNovaFilterUpdateByCurrency';
import { useSelector } from 'src/hooks/useSelector';

/**
 * Свойства компонента.
 *
 * @prop [parentFilterKey] Ключ кластера фильтров в котором находится выбор валюты. Необходим для корректного
 * позиционирования подсказки после изменения валюты.
 */
interface CurrencySelectorProps {
    parentFilterKey?: NovaFilterKey;
    onCurrencySelectorChange?: () => void;
}

/**
 * Компонент переключения валюты в фильтрах поиска.
 */
const CurrencySelector: FC<CurrencySelectorProps> = ({ parentFilterKey, onCurrencySelectorChange }) => {
    const setCurrency = useNovaFilterUpdateByCurrency();
    const criteriaCurrencyCode = useSelector((state) => state.criteriaCurrencyCode);
    const list = useSelector((state) => state.currencies.list);

    const [isSelectorVisible, setSelectorVisible] = useState(false);

    const selectorHost = useRef(null);

    const { isMobile } = useBreakpoint();

    const content = list.map(({ code }) => (
        <Cell
            key={code}
            vertPadding
            horPadding
            roundedBorder
            onClick={() => {
                onCurrencySelectorChange?.();
                setCurrency(code, parentFilterKey);
                setSelectorVisible(false);
            }}
        >
            <CellText>
                <Currency value={code} />
            </CellText>
        </Cell>
    ));

    return (
        <>
            {isMobile ? (
                <BottomSheet visible={isSelectorVisible} onClose={() => setSelectorVisible(false)}>
                    {content}
                </BottomSheet>
            ) : (
                <Drop
                    visible={isSelectorVisible}
                    activatorRef={selectorHost}
                    onClose={() => setSelectorVisible(false)}
                    placement="bottom-right"
                    maxWidth={100}
                >
                    {content}
                </Drop>
            )}
            <div ref={selectorHost}>
                <Link
                    style="neutral"
                    iconRight={ChevronDownOutlinedSize16}
                    Element="button"
                    onClick={() => setSelectorVisible(!isSelectorVisible)}
                >
                    <Currency value={criteriaCurrencyCode} />
                </Link>
            </div>
        </>
    );
};

export default CurrencySelector;
